// AuthContext.js
import React, { createContext, useState, useEffect, useContext } from "react";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(
  "pk_test_51O9InDHLaTqDI0ZJt9X9inyeqysaKIpQAtw1qnPdMjBINSboLSkFgCXXYSCn6wpGmeJ5IeSeRizcIPF3Cydxb4xA00ECeab2eK"
);

const StripeContext = createContext();

export const useStripe = () => useContext(StripeContext);

export const StripeProvider = ({ children }) => {
  return (
    <StripeContext.Provider value={{ stripePromise }}>
      {children}
    </StripeContext.Provider>
  );
};
