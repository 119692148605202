// AuthContext.js
import React, { createContext, useState, useEffect, useContext } from "react";
import { firebase } from "../../../firebase/config";
import mixpanel, { init } from "mixpanel-browser";
import axios from "axios"; // Ensure axios is imported

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [loggedIn, setLoggedIn] = useState(null);
  const [loading, setLoading] = useState(true);
  const [credits, setCredits] = useState(null);
  const [paid, setPaid] = useState(null);
  const [currency, setCurrency] = useState("usd"); // Default currency
  const [price, setPrice] = useState(null); // Initialize price state
  const [signUpTime, setSignUpTime] = useState(null);
  const [offerTimeLeft, setOfferTimeLeft] = useState("");
  const [onboarded, setOnboarded] = useState(null);
  const [workspaceID, setWorkSpaceID] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [website, setWebsite] = useState("");
  const [companyType, setCompanyType] = useState("");
  const [companySize, setCompanySize] = useState(null);
  const [members, setMembers] = useState([]);
  const [projectIDs, setProjectIDs] = useState([]);
  const [projects, setProjects] = useState([]);
  const [favorites, setFavorites] = useState([]);
  const [active, setActive] = useState(false);
  const [currentPlan, setCurrentPlan] = useState("");
  const [ugc, setUGC] = useState([]);

  useEffect(() => {
    if (projectIDs) {
      let initProjects = [];
      projectIDs.forEach(async (projectID) => {
        const docRef = await firebase
          .firestore()
          .collection("projects")
          .doc(projectID)
          .get();
        initProjects.push({ ...docRef.data(), projectID: docRef.id });
      });
      setProjects(initProjects);
    }
  }, [projectIDs]);

  useEffect(() => {
    if (firebase.auth().currentUser) {
      const uid = firebase.auth().currentUser.uid;
      const email = firebase.auth().currentUser.email;

      mixpanel.identify(uid);
      firebase
        .firestore()
        .collection("users")
        .doc(uid)
        .onSnapshot((doc) => {
          if (doc.data()) {
            setPaid(doc.data().paid);
            setSignUpTime(doc.data().signUpTime?.toDate());
            setWorkSpaceID(doc.data().activeWorkSpace);
            if (doc.data().onboarded) {
              setOnboarded(doc.data().onboarded);
            } else {
              setOnboarded(false);
            } // Convert Firestore Timestamp to Date
          }
        });
    }
  }, [firebase.auth().currentUser]);

  useEffect(() => {
    const calculateTimeLeft = () => {
      if (signUpTime) {
        const offerDurationHours = 12; // Duration of the offer in hours
        const offerEndTime = new Date(
          signUpTime.getTime() + offerDurationHours * 60 * 60 * 1000
        );
        const now = new Date();
        const timeLeft = offerEndTime - now;

        if (timeLeft > 0) {
          const hours = Math.floor((timeLeft / (1000 * 60 * 60)) % 24);
          const minutes = Math.floor((timeLeft / 1000 / 60) % 60);
          const seconds = Math.floor((timeLeft / 1000) % 60);

          setOfferTimeLeft(`${hours}hrs ${minutes}mins ${seconds}secs`);
        } else {
          setOfferTimeLeft("Offer has expired");
        }
      }
    };

    // Calculate time left immediately and then set an interval
    calculateTimeLeft();
    const intervalId = setInterval(calculateTimeLeft, 1000); // Update every second

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, [signUpTime]);

  useEffect(() => {
    if (workspaceID) {
      firebase
        .firestore()
        .collection("workspace")
        .doc(workspaceID)
        .onSnapshot((doc) => {
          setCredits(doc.data().credits);
          setCompanyName(doc.data().companyName);
          setWebsite(doc.data().website);
          setCompanySize(doc.data().companySize);
          setCompanyType(doc.data().companyType);
          setCurrentPlan(doc.data().currentPlan);
          setActive(doc.data().active);
          if (doc.data().favorites) {
            setFavorites(doc.data().favorites);
          } else {
            setFavorites([]);
          }
          setMembers(doc.data().members);
          if (doc.data().projects) {
            setProjectIDs(doc.data().projects);
          } else {
            setProjectIDs([]);
          }
        });
    }
  }, [workspaceID]);

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      setLoggedIn(!!user);
      setLoading(false);
    });

    return unsubscribe; // Unsubscribe on unmount
  }, []);

  return (
    <AuthContext.Provider
      value={{
        loggedIn,
        loading,
        credits,
        paid,
        currency,
        price,
        offerTimeLeft,
        signUpTime,
        onboarded,
        setOnboarded,
        workspaceID,
        companyName,
        website,
        companyType,
        companySize,
        members,
        projects,
        setProjects,
        setUGC,
        ugc,
        favorites,
        setFavorites,
        active,
        currentPlan,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
