import React, { Component, useState, useEffect } from "react";
import "../index.css";
import "./index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter, useHistory } from "react-router-dom";
import { firebase } from "../../../firebase/config";
import "react-phone-number-input/style.css";
import { FaLink, FaRegStar } from "react-icons/fa";
import { useSignUpModal } from "../Context/SignUpModal";
import { useAuth } from "../Context/AuthContext";
import { MdEdit } from "react-icons/md";
import {
  IoIosArrowDown,
  IoIosInformationCircleOutline,
  IoMdCloseCircle,
} from "react-icons/io";
import MuxPlayer from "@mux/mux-player-react";
import { v4 as uuidv4 } from "uuid";
import { IoInformationCircleOutline } from "react-icons/io5";
import Filter from "./Filter";

const Categories = () => {
  const [templates, setTemplates] = useState([]);
  const [filteredTemplates, setFilteredTemplates] = useState([]);
  const [selectedUGC, setSelectedUGC] = useState("");
  const { favMode } = useSignUpModal();
  const [filterOpen, setFilterOpen] = useState(false);
  const { favorites, workspaceID } = useAuth();
  const [filterIndex, setFilterIndex] = useState(0);
  const [industryArray, setIndustryArray] = useState([]);
  const [filterArrays, setFilterArrays] = useState({
    industry: [],
    objective: [],
    region: [],
    language: [],
    ctr: [],
  });
  const history = useHistory();

  useEffect(() => {
    setFilterOpen(false);
  }, []);

  useEffect(() => {
    firebase
      .firestore()
      .collection("templates")
      .get()
      .then((querySnapshot) => {
        let items = [];
        querySnapshot.forEach((doc) => {
          items.push(doc.data());
        });
        setTemplates(items);
        setFilteredTemplates(items);
      });
  }, []);

  useEffect(() => {
    const filterTemplates = () => {
      let filtered = templates;

      // Filter by Language
      if (filterArrays.language.length > 0) {
        filtered = filtered.filter((template) =>
          filterArrays.language.includes(template.Language)
        );
      }

      // Filter by Industry
      if (filterArrays.industry.length > 0) {
        filtered = filtered.filter((template) =>
          filterArrays.industry.includes(template.Industry)
        );
      }

      // Filter by Objective
      if (filterArrays.objective.length > 0) {
        filtered = filtered.filter((template) =>
          filterArrays.objective.includes(template.Objective)
        );
      }

      // Filter by Region
      if (filterArrays.region.length > 0) {
        filtered = filtered.filter((template) =>
          filterArrays.region.includes(template.Region)
        );
      }

      // Filter by CTR
      if (filterArrays.ctr.length > 0) {
        filtered = filtered.filter((template) => {
          const ctrPercentage = template.CTR;
          return filterArrays.ctr.some((range) => {
            const [minStr, maxStr] = range.split("~");
            const min = Number(minStr.replace(/\D/g, ""));
            const max = Number(maxStr.replace(/\D/g, ""));
            return ctrPercentage >= min && ctrPercentage <= max;
          });
        });
      }

      setFilteredTemplates(filtered);
    };

    filterTemplates();
  }, [filterArrays, templates]);

  const createProjectFromTemplate = async (templateID) => {
    const db = firebase.firestore();

    try {
      // Step 1: Fetch the template document
      const templateDoc = await db
        .collection("templates")
        .doc(templateID)
        .get();

      if (!templateDoc.exists) {
        console.error("Template not found");
        return; // Exit if no template found
      }

      const templateData = templateDoc.data();
      delete templateData.template; // Step 2: Remove the template field

      // Step 3: Create a new project using template data
      const newProjectRef = await db.collection("projects").add({
        ...templateData,
        status: "Draft",
        createdAt: firebase.firestore.FieldValue.serverTimestamp(), // Ensure timestamp is set when creating
      });

      // Step 4: Update the new project document with additional fields
      await db
        .collection("projects")
        .doc(newProjectRef.id)
        .update({
          projectID: newProjectRef.id,
          projectThumbnail: `https://image.mux.com/${templateData.playbackID}/thumbnail.png`,
          videoItems: templateData.videoItems.map((item) => ({
            ...item,
            videoRef: React.createRef(), // Reset video references for React
          })),
        });

      // Redirect to the new project's page
      history.push(
        `/project?projectID=${newProjectRef.id}&projectName=${
          templateData.projectName || "Untitled"
        }`
      );
    } catch (error) {
      console.error("Failed to create project from template", error);
    }
  };

  const createProject = async () => {
    firebase
      .firestore()
      .collection("projects")
      .add({
        projectName: "Untitled",
        workspaceID: workspaceID,
        status: "Draft",
        createdAt: firebase.firestore.FieldValue.serverTimestamp(), // Add createdAt field with server timestamp
      })
      .then((docRef) => {
        firebase
          .firestore()
          .collection("projects")
          .doc(docRef.id)
          .update({
            projectID: docRef.id,
            projectThumbnail: `https://image.mux.com/geLnlLTfethxYfPOtTI02yblFLNH8ANk4pkdSD3U5rbk/thumbnail.png`,
            videoItems: [
              {
                // videoURL: actor.videoURL,
                videoURL: "",
                // Replace with the URL of the second video
                videoRef: React.createRef(),
                startTime: 0,
                initialStartTime: 0,
                endTime: 17,
                initialEndTime: 17,
                trimStart: 0,
                trimEnd: 17,
                x: "50%",
                y: "50%",
                width: "100%",
                height: "100%",
                layerIndex: 2,
                type: "actor",
                thumbnail: `https://image.mux.com/geLnlLTfethxYfPOtTI02yblFLNH8ANk4pkdSD3U5rbk/thumbnail.png`,
                transparentThumbnail:
                  "https://replicate.delivery/pbxt/fPyZyr5ZdF20b6WY8KXzcEynZdINCSBaKtWMPYQZpbbfQfSlA/out.png",
                ugcID: "21a2176e-9840-4f04-bbd8-f306b66048a4",
                name: "Mandy",
                voiceID: "ydn2CvdL94AfVT5LYj68",
                rotation: 0,
                transparentURL:
                  "https://firebasestorage.googleapis.com/v0/b/ugcai-app.appspot.com/o/transparent%2Fmandytraining%20(1).mp4?alt=media&token=597d60d6-0dd4-4aa2-b3d9-4071a8c2cc6a",
                volume: 1,
              },
            ],
          })
          .then(() => {
            history.push(
              `/project?projectID=${docRef.id}&projectName=Untitled`
            );
          });
      });
  };

  function formatNumber(num) {
    if (num >= 1000000) {
      return (num / 1000000).toFixed(1) + "M"; // For millions
    } else if (num >= 1000) {
      return (num / 1000).toFixed(0) + "k"; // For thousands
    } else {
      return num.toString(); // For numbers less than 1000
    }
  }

  const handleFilterChange = (category, value) => {
    setFilterArrays((prevFilterArrays) => {
      let updatedArray;
      if (value === null) {
        updatedArray = []; // Clear the array if value is null
      } else {
        updatedArray = prevFilterArrays[category].includes(value)
          ? prevFilterArrays[category].filter((item) => item !== value)
          : [...prevFilterArrays[category], value];
      }
      return {
        ...prevFilterArrays,
        [category]: updatedArray,
      };
    });
  };

  useEffect(() => {
    console.log(filterArrays);
  }, [filterArrays]);

  return (
    <>
      <MDBRow
        style={{
          width: "100%",
          paddingLeft: 30,
          paddingBottom: 20,
        }}
      >
        <MDBCol className="d-flex justify-content-center" size="12">
          <p style={{ fontFamily: "SSBold", fontSize: 45 }}>
            Choose a top-performing UGC template
          </p>
        </MDBCol>
        <MDBCol className="d-flex justify-content-center" size="12">
          <p
            style={{
              fontFamily: "SSRegular",
              fontSize: 19,
              textAlign: "center",
              marginTop: -17,
            }}
          >
            Use AI to customize the top performing UGC templates below or{" "}
            <p
              style={{
                color: "blue",
                textDecoration: "underline",
                cursor: "pointer",
              }}
              className="d-inline"
              onClick={() => createProject()}
            >
              start from blank.
            </p>
          </p>
        </MDBCol>
        <MDBCol
          className="d-flex justify-content-center"
          style={{ marginTop: 0 }}
          size="12"
        >
          <MDBRow>
            {["Industry", "Objective", "Region", "Language", "CTR"].map(
              (text, index) => (
                <>
                  <div
                    key={index}
                    style={{
                      width: 200,
                      height: 45,
                      border:
                        filterIndex === index
                          ? "1px solid black"
                          : "1px solid #e2e2e2",
                      borderRadius: 100,
                      display: "inline-block",
                      marginLeft: index === 0 ? 0 : 16,
                      paddingLeft: 19,
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setFilterIndex(index);
                      setFilterOpen(true);
                    }}
                  >
                    {filterArrays[text.toLowerCase()]?.length > 0 ? (
                      <>
                        <div
                          style={{
                            height: 35,
                            width: 120,
                            marginTop: 4.5,
                            backgroundColor: "#f2f2f2",
                            borderRadius: 900,
                            paddingTop: 5,
                            paddingLeft: 16,
                            marginLeft: -8,
                            fontFamily: "SSMedium",
                          }}
                        >
                          {filterArrays[text.toLowerCase()][0]?.length > 10
                            ? `${filterArrays[text.toLowerCase()][0].slice(
                                0,
                                10
                              )}...`
                            : filterArrays[text.toLowerCase()][0]}
                        </div>
                        <div
                          style={{
                            position: "absolute",
                            textAlign: "center",
                            backgroundColor: "#f2f2f2",
                            height: 30,
                            width: 30,
                            marginTop: -33,
                            marginLeft: 115,
                            borderRadius: 200,
                            textAlign: "center",
                            fontFamily: "SSMedium",
                            paddingTop: 2,
                          }}
                        >
                          {filterArrays[text.toLowerCase()].length}
                        </div>
                        {filterArrays[text.toLowerCase()].length > 0 ? (
                          <IoMdCloseCircle
                            onClick={(e) => {
                              e.stopPropagation();
                              handleFilterChange(text.toLowerCase(), null);
                            }}
                            style={{
                              position: "absolute",
                              marginTop: -25,
                              marginLeft: 150,
                              opacity: 0.8,
                            }}
                          />
                        ) : (
                          <IoIosArrowDown
                            style={{
                              position: "absolute",
                              marginTop: -25,
                              marginLeft: 150,
                              opacity: 0.8,
                            }}
                          />
                        )}
                      </>
                    ) : (
                      <>
                        <p
                          style={{
                            fontFamily: "SSRegular",
                            fontSize: 20,
                            marginTop: 7,
                            opacity: 0.5,
                          }}
                        >
                          {text}
                        </p>
                        <IoIosArrowDown
                          style={{
                            position: "absolute",
                            marginTop: -39,
                            marginLeft: 150,
                            opacity: 0.8,
                          }}
                        />
                      </>
                    )}
                  </div>
                  {filterOpen && filterIndex === index && (
                    <Filter
                      index={index}
                      filterOpen={filterOpen}
                      setFilterOpen={setFilterOpen}
                      filterIndex={filterIndex}
                      setIndustryArray={setIndustryArray}
                      industryArray={industryArray}
                      filterArrays={filterArrays}
                      handleFilterChange={handleFilterChange}
                    />
                  )}
                </>
              )
            )}
          </MDBRow>
        </MDBCol>
        {filteredTemplates.map((item) => {
          return (
            <MDBCol size="3">
              <div
                style={{
                  paddingBottom: 20,
                  width: "100%",
                  backgroundColor: "white",
                  marginBottom: 30,
                  borderRadius: 9,
                }}
                className="d-flex justify-content-center"
              >
                <MDBRow style={{ paddingTop: 14, width: 330 }}>
                  <MDBCol
                    className="d-flex justify-content-center"
                    style={{ marginTop: 10 }}
                    size="12"
                  >
                    <div
                      style={{
                        height: 533,
                        width: 330,
                        borderRadius: 9,
                        overflow: "hidden",
                        display: "inline-block",
                        padding: 0,
                      }}
                    >
                      <MuxPlayer
                        streamType="on-demand"
                        accent-color="blue"
                        playbackId={item.playbackID}
                        style={{
                          display: "inline-block",
                          height: 533,
                          width: "100%",
                        }}
                      />
                    </div>
                  </MDBCol>
                  <MDBCol
                    className="d-flex justify-content-center"
                    style={{ marginTop: 16 }}
                    size="12"
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                        width: "100%",
                        fontFamily: "SSBold",
                        fontSize: 20,
                      }}
                    >
                      <p style={{ textAlign: "center" }}>
                        {item.Likes && formatNumber(item.Likes)}
                        <br />
                        <p style={{ fontFamily: "SSRegular", fontSize: 16 }}>
                          Likes
                        </p>
                      </p>
                      <p style={{ textAlign: "center" }}>
                        Top {item.CTR}%
                        <br />
                        <p style={{ fontFamily: "SSRegular", fontSize: 16 }}>
                          CTR
                        </p>
                      </p>{" "}
                      <p style={{ textAlign: "center" }}>
                        {item.Budget}
                        <br />
                        <p style={{ fontFamily: "SSRegular", fontSize: 16 }}>
                          Budget
                        </p>
                      </p>
                    </div>
                  </MDBCol>
                  <MDBCol
                    style={{ marginTop: -9 }}
                    className="d-flex justify-content-between"
                    size="12"
                  >
                    <div
                      style={{
                        height: 30,
                        width: 130,
                        borderRadius: 100,
                        textAlign: "center",
                        fontFamily: "SSRegular",
                        fontSize: 14,
                        border: "1px solid blue",
                        color: "blue",
                        paddingTop: 3,
                        cursor: "pointer",
                      }}
                      onClick={() => createProjectFromTemplate(item.templateID)}
                    >
                      <MdEdit style={{ marginRight: 4 }} />
                      Edit template
                    </div>
                    <div style={{ marginTop: -3, opacity: 0.7 }}>
                      <a
                        style={{
                          marginTop: 13,
                          marginRight: 8,
                          color: "black",
                        }}
                        href={item.tiktokLink}
                        target="_blank"
                      >
                        <FaLink
                          style={{
                            fontSize: 16,
                            marginTop: 6,
                            cursor: "pointer",
                          }}
                        />
                      </a>
                      <IoInformationCircleOutline
                        style={{
                          marginRight: 8,
                          fontSize: 23,
                          marginTop: 7,
                          cursor: "pointer",
                          color: "black",
                        }}
                      />
                    </div>
                  </MDBCol>
                </MDBRow>
              </div>
            </MDBCol>
          );
        })}
      </MDBRow>
    </>
  );
};

export default withRouter(Categories);
