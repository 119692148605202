import React, { Component, useState, useEffect } from "react";
import "../index.css";
import "./index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter, useHistory } from "react-router-dom";
import { BiRightArrowAlt } from "react-icons/bi";
import { RiLogoutCircleLine, RiMenu5Line } from "react-icons/ri";
import { firebase } from "../../../firebase/config";
import Signup from "./Signup";
import { useSignUpModal } from "../Context/SignUpModal";
import { useAuth } from "../Context/AuthContext";
import Popup from "./Popup";
import mixpanel from "mixpanel-browser";
import { useTranslation } from "react-i18next";
import Credits from "./Credits";
import TingenSVG from "./TINGEN.svg";
import Logo from "./logo.svg";

const NavBar = () => {
  const { isOpen, setIsOpen, creditsOpen, setCreditsOpen } = useSignUpModal();
  const [loggedIn, setLoggedIn] = useState(false);
  const [popUpVisible, setPopUpVisible] = useState(false);
  const { credits } = useAuth();
  const history = useHistory(); // Add this line
  const { t } = useTranslation();
  const { paid, offerTimeLeft, signUpTime } = useAuth();

  useEffect(() => {
    if (!firebase.auth().currentUser) {
      setLoggedIn(false);
    } else {
      setLoggedIn(true);
    }
  }, [firebase.auth().currentUser]);

  const textRepetitions = Array.from({ length: 100 }, (_, i) => i + 1);

  return (
    <>
      {/* <Signup isOpen={isOpen} setIsOpen={setIsOpen} /> */}
      {/* <Credits setCreditsOpen={setCreditsOpen} credits={creditsOpen} /> */}
      {/* <Popup popUpVisible={popUpVisible} setPopUpVisible={setPopUpVisible} /> */}
      {/* {firebase.auth().currentUser && signUpTime && !paid && (
        <div
          style={{
            width: "100vw",
            height: 50,
            backgroundColor: "#000",
            position: "fixed",
            zIndex: 1000,
            top: 0,
            overflow: "hidden",
            color: "white",
          }}
        >
          <div
            style={{ paddingTop: 6, fontFamily: "MAHeavy", fontSize: 24 }}
            className="scrolling-text"
          >
            {textRepetitions.map((text, index) => (
              <span style={{ marginRight: "60px", color: "white" }}>
                50% off deal - {offerTimeLeft} left
              </span>
            ))}
          </div>
        </div>
      )} */}

      <MDBRow
        className="d-flex justify-content-center"
        style={{
          width: "105vw",
          zIndex: 970,
          height: 77,
          border: "1px solid transparent",
          position: "sticky",
          backgroundColor: "white",
        }}
      >
        <MDBCol
          style={{
            borderBottom: "0px solid rgb(234, 236, 240)",
            height: 77,
            borderRadius: 200,
            marginTop: 10,
            width: "100vw",
            backgroundColor: "white",
          }}
          className="d-flex justify-content-center"
          size="12"
        >
          <div
            style={{ width: 1200 }}
            className="d-flex justify-content-between"
          >
            <MDBRow style={{ paddingTop: 23 }}>
              <MDBCol size="4">
                <NavLink
                  style={{
                    display: "inline-block",
                    cursor: "pointer",
                    zIndex: 4000,
                  }}
                  to="/"
                  exact
                >
                  <div
                    style={{
                      color: "#30312c",
                      fontSize: 24,
                      display: "flex",
                      alignItems: "center",
                      marginLeft: 20,
                      fontFamily: "PPBold",
                      cursor: "pointer",
                      zIndex: 4000,
                    }}
                  >
                    <img
                      style={{
                        height: 27,
                        marginTop: -2.4,
                      }}
                      src={Logo}
                    />
                    <NavLink
                      style={{
                        display: "inline-block",
                        cursor: "pointer",
                        zIndex: 4000,
                        color: "black",
                        marginLeft: 5,
                        fontFamily: "SSBold",
                      }}
                      to="/"
                      exact
                    >
                      TINGEN
                    </NavLink>
                  </div>
                </NavLink>
              </MDBCol>
            </MDBRow>
            <MDBRow style={{ paddingTop: 25, marginLeft: 300 }}>
              <MDBCol
                size="4"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingLeft: 0,
                  width: 500,
                  paddingTop: 7,
                  opacity: 0.9,
                  fontFamily: "SSMono",
                  color: "black",
                  fontSize: 15,
                }}
              >
                <NavLink
                  to="/pricing"
                  exact
                  style={{
                    display: "inline-block",
                    marginRight: 4,
                    marginLeft: 0,
                    cursor: "pointer",
                    opacity: 1,
                    width: 120,
                    textAlign: "center",
                    color: "black",
                  }}
                >
                  Pricing
                </NavLink>

                <NavLink
                  to="/faq"
                  exact
                  style={{
                    display: "inline-block",
                    marginRight: 4,
                    marginLeft: 35,
                    cursor: "pointer",
                    opacity: 1,
                    width: 190,
                    textAlign: "center",
                    color: "black",
                  }}
                >
                  FAQ
                </NavLink>

                <a
                  href="mailto:help@cloneshort.com"
                  target="_blank"
                  style={{
                    display: "inline-block",
                    marginRight: 4,
                    marginLeft: 35,
                    cursor: "pointer",
                    opacity: 1,
                    width: 240,
                    textAlign: "center",
                    color: "black",
                    whiteSpace: "nowrap",
                  }}
                >
                  Join our Discord
                </a>
              </MDBCol>
            </MDBRow>
            <MDBRow style={{ marginLeft: 80 }}>
              <MDBCol className="d-flex justify-content-between" size="8">
                <NavLink
                  style={{
                    height: 57,
                    width: 100,
                    border: "2px solid transparent",
                    borderRadius: 10,
                    marginTop: 12,
                    backgroundColor: "transparent",
                    opacity: 1,
                    cursor: "pointer",
                  }}
                  exact
                  to="/auth"
                  className="d-flex justify-content-center"
                >
                  <p
                    style={{
                      display: "inline-block",
                      fontFamily: "SSMono",
                      color: "black",
                      marginLeft: 0,
                      marginTop: 14,
                      marginRight: 0,
                      marginLeft: 0,
                      cursor: "pointer",
                      fontSize: 17,
                      opacity: 1,
                      width: 140,
                      textAlign: "center",
                    }}
                  >
                    Log in
                  </p>
                </NavLink>
                <NavLink
                  style={{
                    height: 44,
                    width: 250,
                    borderRadius: 0,
                    marginTop: 17,
                    background: "blue",
                    opacity: 14,
                    marginLeft: 5,
                  }}
                  to="/auth"
                  exact
                  className="d-flex justify-content-center"
                >
                  <p
                    style={{
                      fontFamily: "SSMedium",
                      fontSize: 15,
                      display: "inline-block",
                      color: "white",
                      marginLeft: 0,
                      cursor: "pointer",
                      marginTop: 11,
                      zIndex: 3000,
                    }}
                  >
                    Try it free
                  </p>
                </NavLink>
              </MDBCol>
            </MDBRow>
          </div>
        </MDBCol>
      </MDBRow>
    </>
  );
};

export default withRouter(NavBar);
