import React, { Component, useState, useEffect } from "react";
import "../index.css";
import "./index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter, useHistory } from "react-router-dom";
import { BiRightArrowAlt } from "react-icons/bi";
import { GoQuestion } from "react-icons/go";
import {
  RiLogoutCircleLine,
  RiMenu5Line,
  RiSearchLine,
  RiUserAddLine,
} from "react-icons/ri";
import { firebase } from "../../../firebase/config";
import Signup from "./Signup";
import { useSignUpModal } from "../Context/SignUpModal";
import { useAuth } from "../Context/AuthContext";
import Popup from "./Popup";
import mixpanel from "mixpanel-browser";
import { useTranslation } from "react-i18next";
import {
  FaCoins,
  FaDiscord,
  FaRegBell,
  FaRegQuestionCircle,
} from "react-icons/fa";
import { IoClose, IoSearch } from "react-icons/io5";
import { BsFillLightningChargeFill } from "react-icons/bs";
import Credits from "./Credits";
import Invite from "./Invite";
import Notifications from "./Notifications";
import AccountPopUp from "./AccountPopUp";
import Onboarding from "./Onboarding";
import CloneModal from "./CloneModal";
import AssetModal from "./AssetModal";
import Logo from "./logo.svg";

const MainMenu = () => {
  const {
    isOpen,
    setIsOpen,
    creditsOpen,
    setCreditsOpen,
    invitesOpen,
    setInvitesOpen,
    notiOpen,
    setNotiOpen,
    accountOpen,
    setAccountOpen,
  } = useSignUpModal();
  const { onboarded, credits } = useAuth();
  const [loggedIn, setLoggedIn] = useState(false);
  const [popUpVisible, setPopUpVisible] = useState(false);
  const [section, setSection] = useState("library");

  useEffect(() => {
    setAccountOpen(false);
  }, []);

  useEffect(() => {
    if (!firebase.auth().currentUser) {
      setLoggedIn(false);
    } else {
      setLoggedIn(true);
    }
  }, [firebase.auth().currentUser]);

  // useEffect(() => {
  //   firebase.auth().signOut();
  // }, []);

  console.log(window.location.pathname);

  return (
    <>
      <MDBRow
        style={{
          width: "105vw",
          position: "fixed",
          backgroundColor: "#fff",
          zIndex: 970,
          height: 77,
          borderBottom: "1px solid #eaecf0",
        }}
      >
        <MDBCol
          style={{
            borderBottom: "0px solid rgb(234, 236, 240)",
            height: 77,
            position: "fixed",
            zIndex: 900,
            borderRadius: 0,
            width: "105vw",
            marginTop: 0,
          }}
          className="d-flex justify-content-between"
          size="12"
        >
          <MDBRow style={{ paddingTop: 23 }}>
            <MDBCol size="3">
              <NavLink onClick={() => setAccountOpen(false)} to="/" exact>
                <div
                  className="d-flex justify-content-center"
                  style={{
                    height: 47,
                    width: 47,
                    marginLeft: 15,
                    marginTop: -9,
                    borderRadius: 9,
                  }}
                >
                  <img
                    style={{
                      height: 37,
                      marginTop: 10,
                    }}
                    src={Logo}
                  />
                </div>
              </NavLink>
              <hr
                style={{
                  backgroundColor: "#eaecf0",
                  width: 77,
                  height: 0.3,
                  transform: "rotate(90deg)",
                  position: "absolute",
                  left: 50,
                  marginTop: -24,
                }}
              />
            </MDBCol>
            <MDBCol size="3">
              <div style={{ marginTop: -4, marginLeft: 15 }}>
                <NavLink
                  exact
                  to="/upgrade"
                  style={{
                    height: 39,
                    border: "1px solid blue",
                    fontFamily: "SSMedium",
                    fontSize: 15,
                    paddingTop: 8,
                    backgroundColor: "rgb(245, 245, 255)",
                    color: "blue",
                    borderRadius: 4,
                    minWidth: 160,
                    cursor: "pointer",
                  }}
                  className="d-flex justify-content-center"
                >
                  <p>
                    <FaCoins
                      style={{ fontSize: 14, marginRight: 9, marginTop: -2 }}
                      className="d-inline"
                    />
                    {credits} credits
                  </p>
                </NavLink>
              </div>
            </MDBCol>
          </MDBRow>
          <MDBRow style={{ paddingTop: 13, marginLeft: -78 }}>
            <NavLink
              to="/"
              exact
              style={{
                backgroundColor:
                  window.location.pathname === "/" && "rgb(249, 250, 251)",
                marginTop: 10,
                paddingRight: 14,
                paddingLeft: 14,
                height: 36,
                borderRadius: 3,
                fontFamily: "SSRegular",
                fontSize: 15,
                paddingTop: 7,
                display: "inline-block",
                marginRight: 14,
                cursor: "pointer",
                color: "black",
              }}
              onClick={() => setSection("library")}
            >
              <p>Ad Library</p>
            </NavLink>
            <NavLink
              to="/my-projects"
              exact
              style={{
                backgroundColor:
                  window.location.pathname === "/my-projects" &&
                  "rgb(249, 250, 251)",
                marginTop: 10,
                paddingRight: 14,
                paddingLeft: 14,
                height: 36,
                borderRadius: 3,
                fontFamily: "SSRegular",
                fontSize: 15,
                paddingTop: 7,
                display: "inline-block",
                cursor: "pointer",
                color: "black",
              }}
              onClick={() => setSection("projects")}
            >
              <p>Projects</p>
            </NavLink>
            {/* <NavLink
              to="/my-clones"
              exact
              style={{
                backgroundColor:
                  window.location.pathname === "/my-clones" &&
                  "rgb(249, 250, 251)",
                marginTop: 10,
                paddingRight: 14,
                paddingLeft: 14,
                height: 36,
                borderRadius: 3,
                fontFamily: "SSRegular",
                fontSize: 15,
                paddingTop: 7,
                display: "inline-block",
                marginLeft: 14,
                cursor: "pointer",
                color: "black",
              }}
              onClick={() => setSection("actor")}
            >
              <p>
                Create your AI actor{" "}
                <div
                  style={{
                    backgroundColor: "blue",
                    color: "white",
                    marginLeft: 6,
                    borderRadius: 4,
                    fontSize: 13,
                    paddingTop: 3,
                    paddingBottom: 3,
                    paddingRight: 3,
                    paddingLeft: 3,
                  }}
                  className="d-inline"
                >
                  New
                </div>
              </p>
            </NavLink> */}
          </MDBRow>

          <MDBRow
            style={{
              width: 720,
              marginRight: -440,
              backgroundColor: "transparent",
            }}
          >
            <MDBCol className="d-flex justify-content-between" size="8">
              {/* <div
                style={{
                  height: 52,
                  width: 200,
                  border: "2px solid #0c41ab",
                  borderRadius: 10,
                  marginTop: 12,
                  marginLeft: -180,
                  backgroundColor: "#0c41ab",
                  opacity: 14,
                  cursor: "pointer",
                  position: "absolute",
                }}
                onClick={() => {
                  setInvitesOpen(true);
                }}
                className="d-flex justify-content-center"
              >
                <p
                  style={{
                    display: "inline-block",
                    fontFamily: "PPMedium",
                    color: "#fff",
                    marginLeft: 0,
                    marginTop: 13,
                    marginRight: 0,
                    marginLeft: 0,
                    cursor: "pointer",
                    fontSize: 16,
                    opacity: 1,
                    width: 200,
                    textAlign: "center",
                  }}
                >
                  <FaDiscord
                    style={{ fontSize: 19, marginTop: -2, marginRight: 9 }}
                  />
                  Join our discord
                </p>
              </div> */}
              <div
                style={{
                  height: 39,
                  border: "1px solid rgb(208, 213, 221)",
                  borderRadius: 6,
                  width: 40,
                  marginTop: 18,
                  marginLeft: 38,
                }}
                className="d-flex justify-content-center"
              >
                <a href="mailto:help@tingen.ai" target="_blank">
                  <GoQuestion
                    style={{
                      color: "black",
                      fontSize: 21,
                      cursor: "pointer",
                      marginTop: 9,
                    }}
                  />
                </a>
              </div>
              <Notifications />
              <NavLink
                style={{
                  height: 39,
                  width: 120,
                  border: "1px solid blue",
                  borderRadius: 6,
                  marginTop: 18,
                  marginLeft: 0,
                  marginRight: 0,
                  backgroundColor: "rgb(245, 245, 255)",
                  opacity: 14,
                  cursor: "pointer",
                }}
                to="/upgrade"
                exact
                className="d-flex justify-content-center"
              >
                <p
                  style={{
                    display: "inline-block",
                    fontFamily: "SSRegular",
                    color: "blue",
                    marginTop: 10,
                    marginRight: 0,
                    marginLeft: 0,
                    cursor: "pointer",
                    fontSize: 14,
                    opacity: 1,
                    width: 200,
                    textAlign: "center",
                  }}
                >
                  View plans
                </p>
              </NavLink>

              <div
                style={{
                  height: 39,
                  width: 39,
                  border: "2px solid white",
                  borderRadius: 100,
                  marginTop: 18,
                  marginRight: 190,
                  backgroundColor: "white",
                  opacity: 1,
                }}
                className="d-flex justify-content-center align-items-center"
              >
                <div
                  style={{
                    border: "2px solid #0097a7",
                    height: 37,
                    width: 37,
                    borderRadius: 100,
                    cursor: "pointer",
                    backgroundColor: "#0097a7",
                    color: "white",
                  }}
                  onClick={() => setAccountOpen(true)}
                  className="d-flex justify-content-center"
                >
                  <p
                    style={{
                      fontFamily: "PPBold",
                      fontSize: 18,
                      display: "inline-block",
                      color: "#D8D8D8",
                      marginLeft: 0,
                      cursor: "pointer",
                      marginTop: 4,
                      zIndex: 3000,
                    }}
                  >
                    {firebase.auth().currentUser &&
                      firebase.auth().currentUser.email.charAt(0).toUpperCase()}
                  </p>
                </div>
                <AccountPopUp />
              </div>
            </MDBCol>
          </MDBRow>
        </MDBCol>
      </MDBRow>
    </>
  );
};

export default withRouter(MainMenu);
