import React, { Component, useState, useEffect, useRef } from "react";
import "../index.css";
import "./index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter } from "react-router-dom";
import { AiOutlinePlus } from "react-icons/ai";

const Questions = () => {
  const [isHovered, setIsHovered] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const [playing, setPlaying] = useState(false);
  const [iconVisible, setIconVisible] = useState(true);
  const [openIndex, setOpenIndex] = useState(null);
  const videoRef = useRef();

  const faqs = [
    {
      question: "How does Tingen work?",
      answer:
        "With Tingen, you can browse our collection of UGC ad templates that have been tested and proven effective on platforms like TikTok and Facebook. Select a template, choose from our diverse range of AI actors, and customize the ad to fit your product or service. Our AI technology ensures that your video ad looks and feels authentic.",
    },
    {
      question: "What do we mean by `proven` templates?",
      answer:
        "Our proven templates are based on real, high-performing ads sourced from the TikTok and Facebook Ad Library.\n\nWe analyze these successful ads and recreate them using AI actors and customizable elements.\n\nOur team carefully selects ads that have demonstrated strong engagement, click-through rates, and conversion rates on these platforms.\nWe then break down the key components that make these ads effective, such as the script, pacing, visuals, and emotions conveyed by the actors.\n\nUsing this information, we create templates that capture the essence of these top-performing ads while allowing for customization to suit your specific product or service.\nBy leveraging the power of AI actors and proven ad formulas, we help you create authentic, engaging UGC-style video ads that are optimized for performance on TikTok, Facebook, and other social media platforms.\n\nWith Tingen, you can trust that you're using ad templates that have been tested and proven in the real world, giving you a strong foundation for creating successful video ads that resonate with your target audience.",
    },
    {
      question: "What kind of templates does Tingen offer?",
      answer:
        " We offer a wide variety of UGC ad templates across different categories, such as beauty, fashion, tech, and more. These templates have been tested and optimized for performance on popular social media platforms.",
    },
    {
      question: "What is the process for creating a video ad with Tingen?",
      answer:
        "The process is simple: select a template, choose your AI actor(s), customize the ad with your product or service, and generate your UGC-style video ad. You can then download the ad and use it in your marketing campaigns.",
    },
    {
      question: "How long does it take to create a video ad using Tingen?",
      answer:
        "Creating a video ad with Tingen is quick and easy. Once you've selected your template and AI actor(s), you can customize and generate your ad in just a few clicks. And it takes a few minutes to generate after submitting.",
    },
    {
      question: "Are the AI actors customizable?",
      answer:
        "Yes, you can select from our diverse range of AI actors across various ages, languages, and demographics. You can also control the emotions in their speech to best fit your ad's message.",
    },
    {
      question: "Can I use the video ads I create on any platform?",
      answer:
        "Yes, the video ads you create with Tingen can be downloaded and used on various social media platforms and in your marketing campaigns. Facebook and TikTok are fully supportive of businesses using AI tool to assist with creating marketing content.",
    },
  ];

  const toggleOpen = (index) => {
    if (index === openIndex) {
      setOpenIndex(null);
    } else {
      setOpenIndex(index);
    }
  };

  const handleMouseEnter = (item) => {
    setCurrentItem(item);
    const video = videoRef.current;
    if (video) {
      video.play();
    }
  };

  const handleMouseLeave = () => {
    const video = videoRef.current;
    if (video) {
      video.pause();
      video.currentTime = 0; // Rewind the video to the beginning when pausing
      setCurrentItem(null);
    }
  };

  return (
    <>
      <MDBRow
        style={{
          marginTop: 0,
          paddingTop: 0,
          paddingBottom: 100,
        }}
      >
        <MDBCol className="d-flex justify-content-center" size="12">
          <MDBRow
            style={{
              marginTop: 50,
              width: 1400,
              backgroundColor: "white",
              borderRadius: 0,
              paddingBottom: 50,
              overflowY: "hidden",
              border: "1px solid blue",
            }}
          >
            <MDBCol className="d-flex justify-content-start" size="4">
              <p
                style={{
                  color: "black",
                  fontFamily: "SSBold",
                  fontSize: 62,
                  marginTop: 70,
                  marginLeft: 60,
                  lineHeight: 1,
                  opacity: 0.8,
                }}
              >
                Have
                <br />
                questions?
              </p>
            </MDBCol>
            <MDBCol className="d-flex justify-content-start" size="8">
              <MDBRow style={{ marginTop: 80 }}>
                {faqs.map((faq, index) => (
                  <>
                    <MDBCol
                      key={index}
                      className="d-flex justify-content-start"
                      size="12"
                      style={{ paddingBottom: 0, paddingTop: 0 }}
                    >
                      <div
                        onClick={() => toggleOpen(index)}
                        style={{
                          color: "black",
                          fontFamily: "SSMono",
                          fontSize: 24,
                          marginLeft: 120,
                          lineHeight: 1,
                          opacity: 1,
                          cursor: "pointer",
                          userSelect: "none",
                          paddingTop: 5,
                          paddingBottom: 40,
                          width: 700,
                        }}
                        className="d-flex justify-content-between"
                      >
                        <p>{faq.question}</p>{" "}
                        <div
                          style={{
                            height: 50,
                            width: 50,
                            backgroundColor: "white",
                            borderRadius: 100,
                            marginTop: -16,
                          }}
                          className="d-flex justify-content-center"
                        >
                          <AiOutlinePlus
                            style={{ color: "#30312c", marginTop: 11 }}
                          />
                        </div>
                      </div>
                    </MDBCol>
                    <MDBCol
                      key={index}
                      className="d-flex justify-content-start"
                      size="12"
                      style={{
                        paddingBottom: 0,
                        paddingTop: 0,
                      }}
                    >
                      <div
                        style={{
                          color: "black",
                          fontFamily: "SSRegular",
                          fontSize: 18,
                          marginTop: openIndex === index ? 0 : 0,
                          marginLeft: 120,
                          lineHeight: 1.6,
                          opacity: 0.7,
                          borderBottom: "1px solid grey",
                          width: 700,
                          marginBottom: 40,
                          paddingBottom: openIndex === index ? 50 : 0,
                        }}
                      >
                        {openIndex === index && <div>{faq.answer}</div>}
                      </div>
                    </MDBCol>
                  </>
                ))}
              </MDBRow>
            </MDBCol>
          </MDBRow>
        </MDBCol>
      </MDBRow>
    </>
  );
};

export default withRouter(Questions);
