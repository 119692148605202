import React, { Component, useState, useEffect } from "react";
import "../index.css";
import "./index.css";
import TopMenu from "./TopMenu";
import Collapsible from "react-collapsible";
import MenuDesktop from "./MenuDesktop";
import { MDBRow, MDBCol } from "mdbreact";
import { IoSearch } from "react-icons/io5";
import { NavLink, withRouter } from "react-router-dom";
import ReactInputVerificationCode from "react-input-verification-code";
import NavBar from "./DashboardNavBar";
import Logo from "./logo.svg";
import {
  RiArrowDownSLine,
  RiArrowUpSLine,
  RiArrowRightSLine,
  RiQuestionLine,
  RiMessage2Fill,
  RiSendPlane2Fill,
} from "react-icons/ri";
import mixpanel from "mixpanel-browser";
import { HiLightningBolt, HiSortAscending } from "react-icons/hi";
import ReactStars from "react-stars";

import { firebase } from "../../../firebase/config";

import { SkyLightStateless } from "react-skylight";
import { TiTick } from "react-icons/ti";
import Scroll from "react-scroll";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import {
  FaCentercode,
  FaInstagram,
  FaLinkedin,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa";

const DashboardFooter = () => {
  return (
    <MDBRow
      style={{
        paddingBottom: 40,
        color: "white",
        overflowX: "hidden",
        paddingTop: 0,
        borderTop: "0px solid #323033",
        backgroundColor: "white",
        width: "105vw",
        height: 600,
      }}
      size="12"
      className="d-flex justify-content-center"
    >
      <MDBRow style={{ width: 1500, color: "black" }}>
        <MDBCol size="4">
          <NavLink
            style={{
              display: "inline-block",
              cursor: "pointer",
              zIndex: 4000,
            }}
            to="/"
            exact
          >
            <div
              style={{
                color: "#30312c",
                fontSize: 24,
                display: "flex",
                alignItems: "center",
                marginLeft: 20,
                fontFamily: "PPBold",
                cursor: "pointer",
                zIndex: 4000,
              }}
            >
              <img
                style={{
                  height: 27,
                  marginTop: -2.4,
                }}
                src={Logo}
              />
              <NavLink
                style={{
                  display: "inline-block",
                  cursor: "pointer",
                  zIndex: 4000,
                  color: "black",
                  marginLeft: 5,
                  fontFamily: "SSBold",
                }}
                to="/"
                exact
              >
                TINGEN
              </NavLink>
            </div>
          </NavLink>
          <p
            style={{
              fontFamily: "SSSemiBold",
              marginTop: 20,
              marginLeft: 27,
              fontSize: 19,
              opacity: 0.6,
            }}
          >
            support@tingen.ai
          </p>
          <div
            style={{
              display: "flex",
              marginLeft: 27,
              marginTop: 40,
              fontSize: 27,
              opacity: 0.5,
            }}
          >
            <FaTwitter />
            <FaLinkedin style={{ marginLeft: 30 }} />
            <FaYoutube style={{ marginLeft: 30 }} />
            <FaInstagram style={{ marginLeft: 30 }} />
          </div>
        </MDBCol>
        <MDBCol style={{ fontFamily: "SSMedium" }} size="2">
          <p>Company</p>
          <NavLink
            to="/pricing"
            exact
            style={{
              marginTop: 20,
              fontFamily: "SSRegular",
              color: "black",
            }}
          >
            <p style={{ marginTop: 20, opacity: 0.7, fontFamily: "SSRegular" }}>
              Pricing
            </p>
          </NavLink>
          <NavLink
            to="/faq"
            exact
            style={{
              marginTop: 20,
              fontFamily: "SSRegular",
              color: "black",
            }}
          >
            <p style={{ marginTop: 20, opacity: 0.7, fontFamily: "SSRegular" }}>
              FAQ
            </p>
          </NavLink>
          <p style={{ marginTop: 20, opacity: 0.7, fontFamily: "SSRegular" }}>
            Affiliate Program
          </p>
          <p style={{ marginTop: 20, opacity: 0.7, fontFamily: "SSRegular" }}>
            Contact us
          </p>
        </MDBCol>
        <MDBCol style={{ fontFamily: "SSMedium" }} size="2">
          <p>Legal</p>
          <NavLink
            to="/privacy-policy"
            exact
            style={{
              marginTop: 20,
              opacity: 0.7,
              fontFamily: "SSRegular",
              color: "black",
            }}
          >
            <p>Privacy Policy</p>
          </NavLink>
          <NavLink
            to="/terms-of-service"
            exact
            style={{
              marginTop: 20,
              opacity: 0.7,
              fontFamily: "SSRegular",
              color: "black",
            }}
          >
            Terms of Service
          </NavLink>
        </MDBCol>
        <p
          style={{
            position: "absolute",
            marginTop: 500,
            fontFamily: "SSRegular",
            marginLeft: 20,
            opacity: 0.7,
          }}
        >
          Copyright TingenAI® 2024 Tingen LLC. All rights reserved
        </p>
      </MDBRow>
    </MDBRow>
  );
};

export default withRouter(DashboardFooter);
