import React from "react";
import ReactDOM from "react-dom";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";
import "./index.css";
import App from "./App";
import { SignUpProvider } from "./Components/Parts/Context/SignUpModal.jsx";
import { AuthProvider } from "./Components/Parts/Context/AuthContext";
import { RatesProvider } from "./Components/Parts/Context/RatesContext";
import { LoadingProvider } from "./Components/Parts/Context/LoadingContext.jsx";
import { StripeProvider } from "./Components/Parts/Context/StripeContext.jsx";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import * as Sentry from "@sentry/react";

import "./i18n";

Sentry.init({
  dsn: "https://5d41e04650c30b0105bb0dbd21f116e2@o4506489669025792.ingest.sentry.io/4506490463059968",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    }),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

// import register from './registerServiceWorker';

if (module.hot) {
  module.hot.accept();
}

const stripePromise = loadStripe(
  "pk_live_51O9InDHLaTqDI0ZJxEuxNO9oRhMT6GXZp7lwQYJG518JtdGJV0Tfx84zzl6pI5oe9G0UcwzrWER9tJj4dqOVm3Yd00oZqtXWXs",
  {
    fonts: [
      {
        src: require("./Assets/Fonts/font/MierA-Bold.ttf"),
        family: "MABold",
        style: "normal",
      },
    ],
  }
);

// const stripePromise = loadStripe(
//   "pk_test_51O9InDHLaTqDI0ZJt9X9inyeqysaKIpQAtw1qnPdMjBINSboLSkFgCXXYSCn6wpGmeJ5IeSeRizcIPF3Cydxb4xA00ECeab2eK",
//   {
//     fonts: [
//       {
//         src: require("./Assets/Fonts/font/MierA-Bold.ttf"),
//         family: "MABold",
//         style: "normal",
//       },
//     ],
//   }
// );
ReactDOM.render(
  <Elements stripe={stripePromise}>
    <LoadingProvider>
      <RatesProvider>
        <SignUpProvider>
          <AuthProvider>
            <App />
          </AuthProvider>
        </SignUpProvider>
      </RatesProvider>
    </LoadingProvider>
  </Elements>,
  document.getElementById("root")
);
