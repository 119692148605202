import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/functions";
import "firebase/compat/storage";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAqKNzkdBmZXHd5gVPmNL2SW42AhFTEpvU",
  authDomain: "ugcai-app.firebaseapp.com",
  projectId: "ugcai-app",
  storageBucket: "ugcai-app.appspot.com",
  messagingSenderId: "372056056430",
  appId: "1:372056056430:web:103d80fb7d886a0a24fc7f",
  measurementId: "G-0QF4JWKN75",
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

export { firebase };
