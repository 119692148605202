import React, { Component, useState, useEffect } from "react";
import "../index.css";
import "./index.css";
import TopMenu from "../Layout/TopMenu";
import Collapsible from "react-collapsible";
import MenuDesktop from "../Layout/MenuDesktop";
import { MDBRow, MDBCol } from "mdbreact";
import { IoSearch } from "react-icons/io5";
import { NavLink, withRouter } from "react-router-dom";
import ReactInputVerificationCode from "react-input-verification-code";
import DashboardNavBar from "../Layout/DashboardNavBar";
import DashboardFooter from "../Layout/DashboardFooter";
import { FaRegCircleQuestion } from "react-icons/fa6";
import Match from "./Match";
import axios from "axios";
import DashboardFooterMobile from "../Layout/DashboardFooterMobile";

import {
  RiArrowDownSLine,
  RiArrowUpSLine,
  RiArrowRightSLine,
  RiQuestionLine,
} from "react-icons/ri";
import mixpanel from "mixpanel-browser";
import { HiLightningBolt, HiSortAscending } from "react-icons/hi";
import ReactStars from "react-stars";

import { firebase } from "../../../firebase/config";

import { SkyLightStateless } from "react-skylight";
import { TiTick } from "react-icons/ti";
import Scroll from "react-scroll";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { matches } from "lodash";
import Slide from "react-reveal/Slide";
import Testimonial from "./Testimonial";
import { loadStripe } from "@stripe/stripe-js";
import {
  CardElement,
  Elements,
  ElementsConsumer,
} from "@stripe/react-stripe-js";
import Faq from "react-faq-component";
import PricingBox from "./PricingBox";
import { TailSpin, ThreeDots } from "react-loader-spinner";
import DashboardNavBarMobile from "../Layout/DashboardNavBarMobile";
import { useAuth } from "../Context/AuthContext";

function checkPlanExists(plans, tier, planType) {
  // Convert tier and planType to their corresponding values
  const name = `${tier} Plan`;
  const interval = planType === "Weekly" ? "month" : "year";

  if (!plans) {
    return null;
  }

  // Check if the plan exists in the array
  return plans.some((plan) => plan.name === name && plan.interval === interval);
}

function PriceHeading({
  tier,
  planType,
  subscribedPlans,
  setIsOpen,
  price,
  monthly,
}) {
  const [subscriptionLoading, setSubscriptionLoading] = useState(false);
  const [active, setActive] = useState(null);
  const [workspaceData, setWorkspaceData] = useState(null);
  const { workspaceID } = useAuth();

  useEffect(() => {
    // Assuming `monthly` is a state or prop that indicates if the current view/context is monthly
    // `price` should be defined in your component or passed as props if needed
    if (workspaceData) {
      const isActive =
        ((workspaceData.planType === "Monthly" && monthly) ||
          (workspaceData.planType !== "Monthly" && !monthly)) &&
        price.ads === workspaceData.creditsRenewing;

      setActive(isActive);
    }
  }, [monthly, workspaceData, price, workspaceID]);

  useEffect(() => {
    if (workspaceID) {
      firebase
        .firestore()
        .collection("workspace")
        .doc(workspaceID)
        .onSnapshot((doc) => {
          setWorkspaceData(doc.data());
        });
    }
  }, [workspaceID]);

  useEffect(() => {
    if (checkPlanExists(subscribedPlans, tier, planType)) {
      setActive(true);
    } else {
      setActive(false);
    }
  }, [subscribedPlans, tier, planType]);

  const priceCalculator = (tier) => {
    switch (tier) {
      case "Basic":
        return planType === "Weekly" ? 39 : 29;
      case "Professional":
        return planType === "Weekly" ? 119 : 100;
      case "Enterprise":
        return planType === "Weekly" ? 500 : 299;
    }
  };

  const pricingToStripe = (price) => {
    switch (price) {
      case 39:
        return "price_1Nlu2EKXhAhuLzvpeX8cZe2d";
      case 29:
        return "price_1Nlu2EKXhAhuLzvpmuqoDhRz";
      case 119:
        return "price_1Nlu2JKXhAhuLzvpJ0ohCIwK";
      case 100:
        return "price_1Nlu2JKXhAhuLzvpCIuS5FZT";
      case 299:
        return "price_1Nlu2NKXhAhuLzvpGdrLINui";
      case 500:
        return "price_1Nlu2NKXhAhuLzvpGdrLINui";
    }
  };

  const priceMappings = {
    // Starter tier prices
    60: "price_1P5ubvAujBHs8C4aoswq8z8C", // Monthly price for first starter plan
    48: "price_1P5ud0AujBHs8C4aoKXjsTIZ", // Annual price for first starter plan
    120: "price_1P5ucQAujBHs8C4aqsHvwqQw", // Monthly price for second starter plan
    96: "price_1P5udPAujBHs8C4a1HIjQ9k5", // Annual price for second starter plan

    // Growing tier prices
    150: "price_1P5ue3AujBHs8C4aDYIeNwd5", // Monthly price for first growing plan
    120: "price_1P5uf6AujBHs8C4a3wdzXbEA", // Annual price for first growing plan (same as monthly for another tier, so adjusted)
    300: "price_1P5ueMAujBHs8C4a1yPqIsQq", // Monthly price for second growing plan
    240: "price_1P5ufTAujBHs8C4ai0Lxgs6e", // Annual price for second growing plan
    450: "price_1P5ueYAujBHs8C4apxuiutkB", // Monthly price for third growing plan
    360: "price_1P5ug3AujBHs8C4aYcRqCkhf", // Annual price for third growing plan

    // Scale tier prices
    750: "price_1P5ugyAujBHs8C4aNsYSA4pD", // Monthly price for first scale plan
    600: "price_1P5uiRAujBHs8C4ayfQsLqud", // Annual price for first scale plan
    1125: "price_1P5uhIAujBHs8C4aQLNEK35r", // Monthly price for second scale plan
    900: "price_1P5uiuAujBHs8C4apHC5DhpA", // Annual price for second scale plan
    1500: "price_1P5uhYAujBHs8C4aNu47sNWP", // Monthly price for third scale plan
    1200: "price_1P5ujSAujBHs8C4aWRvnSZ7c", // Annual price for third scale plan
    3000: "price_1P5uhoAujBHs8C4auKuZTzpU", // Monthly price for fourth scale plan
    2400: "price_1P5ujqAujBHs8C4aGH4uAeMf", // Annual price for fourth scale plan
  };

  const startSubscription = async (tier) => {
    setSubscriptionLoading(true);
    const uid = firebase.auth().currentUser.uid;
    const docRef = await firebase
      .firestore()
      .collection("customers")
      .doc(uid)
      .collection("checkout_sessions")
      .add({
        price: priceMappings[monthly ? price.monthly : price.annual],
        success_url: window.location.origin,
        cancel_url: window.location.origin,
        allow_promotion_codes: true,
      });
    // Wait for the CheckoutSession to get attached by the extension
    docRef.onSnapshot((snap) => {
      const { error, url } = snap.data();
      if (error) {
        // Show an error to your customer and
        // inspect your Cloud Function logs in the Firebase console.
        alert(`An error occured: ${error.message}`);
      }
      if (url) {
        // We have a Stripe Checkout URL, let's redirect.
        window.location.assign(url);
      }
    });
  };

  const buttonText = (plan) => {
    switch (plan) {
      case "Free":
        return "Get monthly TikTok's";
        break;
      case "Weekly":
        return "Get weekly TikTok's";
        break;
      case "Daily":
        return "Get daily TikTok's";
        break;
      case "Enterprise":
        return "Managed TikTok";
        break;
    }
  };

  const subheadingText = (plan) => {
    switch (plan) {
      case "Starter":
        return "Just getting started with video ads. Create a few to get going.";
        break;
      case "Growing":
        return "For small businesses needing more affordable but effective video ads.";
        break;
      case "Scale":
        return "For growth teams testing video ad concepts faster than ever.";
        break;
      case "Enterprise":
        return "For global businesses testing ads at scale across regions/platforms";
        break;
    }
  };

  const includedText = (plan) => {
    switch (plan) {
      case "Starter":
        return "10 video ads. 20 templates included";
        break;
      case "Growing":
        return "30 video ads. 100 templates included";
        break;
      case "Scale":
        return "200 videos ads. Unlock all templates.";
        break;
      case "Enterprise":
        return "200+ video ads. Custom templates.";
        break;
    }
  };

  const pricingText = (plan) => {
    switch (plan) {
      case "Starter":
        return "60";
        break;
      case "Growing":
        return "150";
        break;
      case "Scale":
        return "750";
        break;
      case "Enterprise":
        return "Custom";
        break;
    }
  };
  return (
    <MDBRow>
      <MDBCol size="12">
        <p
          style={{
            fontSize: 17,
            fontFamily: "SSMedium",
            marginTop: -17,
          }}
        >
          {tier}
        </p>
      </MDBCol>
      <MDBCol size="12">
        <p
          style={{
            fontSize: 15,
            fontFamily: "SSMedium",
            marginTop: -10,
            opacity: 0.7,
            width: 250,
          }}
        >
          {subheadingText(tier)}
        </p>
      </MDBCol>
      <MDBCol size="12">
        <div
          style={{
            fontSize: 54,
            fontFamily: "SSMedium",
            marginTop: -12,
            color: "#30312c",
            display: "flex",
          }}
        >
          <p
            style={{
              fontSize: 27,
              fontFamily: "SSMono",
              marginBottom: 0,
              marginRight: 5,
              position: "absolute",
              marginTop: 30,
            }}
            className="d-inline"
          >
            {tier !== "Enterprise" && "$"}
          </p>
          <p
            style={{ marginLeft: tier === "Enterprise" ? 0 : 20 }}
            className="d-inline"
          >
            {tier !== "Enterprise"
              ? monthly
                ? price.monthly
                : price.annual
              : "Custom"}
          </p>
          <p
            style={{
              fontSize: 12,
              fontFamily: "SSSemiBold",
              marginTop: monthly ? 45 : 30,
              marginLeft: 5,
            }}
          >
            {tier !== "Enterprise" && !monthly && "Billed annually"}
            {tier !== "Enterprise" && !monthly && <br />}
            {tier !== "Enterprise" && "/ per month"}
          </p>
        </div>
        <p style={{ fontFamily: "SSSemiBold", fontSize: 15, marginTop: -15 }}>
          {tier !== "Enterprise"
            ? `${price.ads} credits. ${price.templates} templates included.`
            : "1000+ video ads. Custom templates."}
        </p>
        <p style={{ fontFamily: "SSMedium", fontSize: 13, marginTop: -13 }}>
          One video credit lasts up to 60 seconds.{" "}
          <FaRegCircleQuestion style={{ marginLeft: 5, marginTop: -2 }} />
        </p>
      </MDBCol>
      <MDBCol className="d-flex justify-content-center" size="12">
        <div
          style={{
            width: 280,
            marginLeft: -20,
            height: 60,
            borderRadius: 8,
            marginTop: 10,
            background: "blue",
            paddingTop: subscriptionLoading ? 17 : 0,
            cursor: !active ? "pointer" : null,
            border: "1px solid rgb(11, 11, 207)",
            color: "rgb(9, 9, 162)",
            backgroundColor: "rgb(245, 245, 255)",
          }}
          className="d-flex justify-content-center"
          onClick={() => {
            if (!subscriptionLoading && !active) {
              startSubscription(tier);
            }
          }}
        >
          {subscriptionLoading ? (
            <TailSpin
              height="30"
              width="30"
              color="blue"
              ariaLabel="tail-spin-loading"
              radius="1"
              wrapperStyle={{ marginTop: -3 }}
              wrapperClass=""
              visible={true}
            />
          ) : active ? (
            <p
              style={{
                marginTop: 16,
                fontFamily: "SSMedium",
                fontSize: 19,
                display: "flex",
                marginLeft: -6,
              }}
            >
              Currently active
            </p>
          ) : (
            <p
              style={{
                marginTop: 16,
                fontFamily: "SSMedium",
                fontSize: 19,
                display: "flex",
                marginLeft: -6,
              }}
            >
              Get started
            </p>
          )}
        </div>
      </MDBCol>
    </MDBRow>
  );
}

export default withRouter(PriceHeading);
