import React, { Component, useState, useEffect } from "react";
import "../index.css";
import "./index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter, useLocation, useHistory } from "react-router-dom";
import DashboardNavBar from "../Layout/DashboardNavBar";
import { firebase } from "../../../firebase/config";

import "react-phone-number-input/style.css";
import MainMenu from "../Layout/MainMenu";
import { FaRegCompass, FaRegStar } from "react-icons/fa";
import { BsBackpack, BsThreeDots } from "react-icons/bs";
import { IoFilterSharp } from "react-icons/io5";
import SideNav from "../Layout/SideNav";
import TopBar from "../Layout/TopBar";
import Categories from "./Categories";
import PackTemplates from "./PackTemplates";
import Templates from "./Templates";
import { useAuth } from "../Context/AuthContext";
import { LuPen } from "react-icons/lu";
import ProjectModal from "./ProjectModal";
import { TailSpin } from "react-loader-spinner";
import { GoDeviceCameraVideo } from "react-icons/go";
import { FiPlay } from "react-icons/fi";
import VideoModal from "./VideoModal";

const MyProjects = () => {
  const { workspaceID } = useAuth();
  const categories = [1, 2, 3, 4, 5, 6, 7, 9, 3, 4, 5, 6, 7, 9];
  const [queryID, setQueryID] = useState();
  const location = useLocation();
  const [projectsArray, setProjectsArray] = useState([]);
  const [projectModalOpen, setProjectModalOpen] = useState(false);
  const [currentProject, setCurrentProject] = useState(null);
  const [currentPlaybackID, setCurrentPlaybackID] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [videoOpen, setVideoOpen] = useState(false);
  const history = useHistory();
  useEffect(() => {
    if (firebase.auth().currentUser && workspaceID) {
      firebase
        .firestore()
        .collection("projects")
        .where("workspaceID", "==", workspaceID)
        .orderBy("createdAt", "desc") // Order by createdAt field in descending order
        .onSnapshot((querySnapshot) => {
          if (querySnapshot.empty) {
            setProjectsArray([]);
            setLoaded(true);
          } else {
            let initArray = [];
            querySnapshot.forEach((doc) => {
              initArray.push(doc.data());
            });
            setProjectsArray(initArray);
            setLoaded(true);
          }
        });
    }
  }, [firebase.auth().currentUser, workspaceID]);

  const formatDate = (timestamp) => {
    const date = timestamp.toDate();
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const createProject = async () => {
    firebase
      .firestore()
      .collection("projects")
      .add({
        projectName: "Untitled",
        workspaceID: workspaceID,
        status: "Draft",
        createdAt: firebase.firestore.FieldValue.serverTimestamp(), // Add createdAt field with server timestamp
      })
      .then((docRef) => {
        firebase
          .firestore()
          .collection("projects")
          .doc(docRef.id)
          .update({
            projectID: docRef.id,
            projectThumbnail: `https://image.mux.com/geLnlLTfethxYfPOtTI02yblFLNH8ANk4pkdSD3U5rbk/thumbnail.png`,
            videoItems: [
              {
                // videoURL: actor.videoURL,
                videoURL: "",
                // Replace with the URL of the second video
                videoRef: React.createRef(),
                startTime: 0,
                initialStartTime: 0,
                endTime: 17,
                initialEndTime: 17,
                trimStart: 0,
                trimEnd: 17,
                x: "50%",
                y: "50%",
                width: "100%",
                height: "100%",
                layerIndex: 2,
                type: "actor",
                thumbnail: `https://image.mux.com/geLnlLTfethxYfPOtTI02yblFLNH8ANk4pkdSD3U5rbk/thumbnail.png`,
                transparentThumbnail:
                  "https://replicate.delivery/pbxt/fPyZyr5ZdF20b6WY8KXzcEynZdINCSBaKtWMPYQZpbbfQfSlA/out.png",
                ugcID: "21a2176e-9840-4f04-bbd8-f306b66048a4",
                name: "Mandy",
                voiceID: "ydn2CvdL94AfVT5LYj68",
                rotation: 0,
                transparentURL:
                  "https://firebasestorage.googleapis.com/v0/b/ugcai-app.appspot.com/o/transparent%2Fmandytraining%20(1).mp4?alt=media&token=597d60d6-0dd4-4aa2-b3d9-4071a8c2cc6a",
                volume: 1,
              },
            ],
          })
          .then(() => {
            history.push(
              `/project?projectID=${docRef.id}&projectName=Untitled`
            );
          });
      });
  };

  return (
    <>
      <div
        style={{
          overflowX: "hidden",
        }}
      >
        <div
          style={{
            overflowX: "hidden",
            color: "#30312c",
            backgroundColor: "white",
            overflowY: "scroll",
            height: "100vh",
            paddingBottom: 30,
          }}
        >
          <MDBCol
            style={{
              width: "105vw",
              overflowX: "hidden",
              paddingBottom: 0,
              paddingBottom: 0,
            }}
            size="12"
          >
            <MDBRow style={{ marginTop: 0 }}>
              <MainMenu />
            </MDBRow>
          </MDBCol>
          <MDBCol
            style={{
              paddingLeft: 0,
              paddingLeft: 30,
              marginTop: 80,
              padddingRight: 30,
            }}
            size="12"
            className="d-flex justify-content-center"
          >
            <VideoModal
              videoOpen={videoOpen}
              setVideoOpen={setVideoOpen}
              playbackID={currentPlaybackID}
            />
            <MDBRow style={{ width: 1500 }}>
              {projectsArray &&
                projectsArray.map((project, index) => {
                  const borderProgress = project.Progress; // Assuming 'progress' is the variable you have
                  const gradient = `linear-gradient(to right, blue ${borderProgress}%, #E7E7FC ${borderProgress}%, #E7E7FC 100%)`;

                  return (
                    <MDBCol size="3">
                      <div
                        className="project-card"
                        style={{
                          borderRadius: 12,
                          marginTop: 20,
                          cursor: "pointer",
                          paddingRight: 4,
                        }}
                        onMouseLeave={() => setProjectModalOpen(false)}
                        onClick={() => {
                          if (
                            project.status === "Draft" ||
                            project.status === "Error"
                          ) {
                            history.push(
                              `/project?projectID=${project.projectID}&projectName=${project.projectName}`
                            );
                          } else if (project.status === "Finished") {
                            setCurrentPlaybackID(project.playbackID);
                            setVideoOpen(true);
                          }
                        }}
                      >
                        <div className="image-container">
                          <img
                            className="image-container"
                            src={project.projectThumbnail}
                            style={{
                              width: 356.6,
                              height: 186,
                              objectFit: "cover",
                              borderRadius: 10.5,
                              marginLeft: index % 4 === 0 ? 0.4 : 0,
                              borderBottom:
                                project.status === "Processing"
                                  ? `6px solid`
                                  : null, // Removed the gradient from here
                              borderImage:
                                project.status === "Processing"
                                  ? `${gradient} 1 / 0 0 6px 0`
                                  : null, // Applying gradient only to the bottom border
                            }}
                          />
                        </div>
                        {(project.status === "Draft" ||
                          project.status === "Error") && (
                          <div
                            style={{
                              position: "absolute",
                              height: 40,
                              width: 40,
                              borderRadius: "50%",
                              backgroundColor: "#fff",
                              marginTop: -116,
                              marginLeft: 160,
                            }}
                            className="d-flex justify-content-center edit-icon"
                          >
                            <LuPen style={{ color: "black", marginTop: 12 }} />
                          </div>
                        )}
                        {project.status === "Finished" && (
                          <div
                            style={{
                              position: "absolute",
                              height: 40,
                              width: 40,
                              borderRadius: "50%",
                              backgroundColor: "#fff",
                              marginTop: -116,
                              marginLeft: 160,
                            }}
                            onClick={(e) => {
                              e.stopPropagation();
                              setCurrentPlaybackID(project.playbackID);
                              setVideoOpen(true);
                            }}
                            className="d-flex justify-content-center edit-icon"
                          >
                            <FiPlay style={{ color: "black", marginTop: 12 }} />
                          </div>
                        )}
                        {project.status === "Processing" && (
                          <>
                            <div
                              style={{
                                position: "absolute",
                                height: 30,
                                width: 30,
                                borderRadius: "50%",
                                backgroundColor: "#fff",
                                marginTop: -46,
                                marginLeft: 10,
                              }}
                              className="d-flex justify-content-center"
                            >
                              <TailSpin
                                height="17"
                                width="17"
                                color="blue"
                                ariaLabel="tail-spin-loading"
                                radius="3"
                                wrapperStyle={{ marginTop: 2 }}
                                wrapperClass="d-inline"
                                visible={true}
                              />
                            </div>
                            <div
                              style={{
                                position: "absolute",
                                height: 30,
                                paddingLeft: 10,
                                paddingRight: 10,
                                borderRadius: 90,
                                backgroundColor: "white",
                                marginTop: -46,
                                marginLeft: 50,
                                fontFamily: "SSMedium",
                                fontSize: 14,
                                paddingTop: 4,
                                color: "blue",
                              }}
                              className="d-flex justify-content-center"
                            >
                              {project.stage}
                            </div>
                          </>
                        )}
                        <div
                          style={{
                            position: "absolute",
                            height: 25,
                            width: 30,
                            borderRadius: 8,
                            backgroundColor: "#fff",
                            top: 35,
                            right: 10,
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            setCurrentProject(project.projectID);
                            setProjectModalOpen(true);
                          }}
                          className="d-flex justify-content-center dots-icon"
                        >
                          <BsThreeDots
                            style={{
                              color: "black",
                              marginTop: 2,
                              fontSize: 22,
                            }}
                          />
                        </div>
                        {currentProject === project.projectID && (
                          <ProjectModal
                            projectModalOpen={projectModalOpen}
                            setProjectModalOpen={setProjectModalOpen}
                            projectID={project.projectID}
                            project={project}
                            assetID={project.assetID}
                          />
                        )}
                        {project.status === "Draft" && (
                          <div
                            style={{
                              position: "absolute",
                              backgroundColor: "#f8e4da",
                              fontFamily: "SSRegular",
                              textAlign: "center",
                              fontSize: 12,
                              marginTop: -30,
                              marginLeft: 10,
                              width: 50,
                              color: "rgb(255, 121, 26)",
                              height: 21,
                              paddingTop: 1.2,
                            }}
                            className="d-flex justify-content-center"
                          >
                            Draft
                          </div>
                        )}

                        {project.status === "Error" && (
                          <div
                            style={{
                              position: "absolute",
                              backgroundColor: "#FFCCCB",
                              fontFamily: "SSRegular",
                              textAlign: "center",
                              fontSize: 12,
                              marginTop: -30,
                              marginLeft: 10,
                              width: 50,
                              color: "red",
                              height: 21,
                              paddingTop: 1.2,
                            }}
                            className="d-flex justify-content-center"
                          >
                            Error
                          </div>
                        )}
                        {project.status === "Finished" && (
                          <div
                            style={{
                              position: "absolute",
                              backgroundColor: "rgb(245, 245, 255)",
                              fontFamily: "SSRegular",
                              textAlign: "center",
                              fontSize: 12,
                              marginTop: -30,
                              marginLeft: 10,
                              paddingLeft: 10,
                              paddingRight: 10,
                              color: "blue",
                              height: 21,
                              paddingTop: 1.4,
                            }}
                            className="d-flex justify-content-center"
                          >
                            <p>
                              <GoDeviceCameraVideo
                                style={{ marginRight: 4, marginTop: -2.4 }}
                                className="d-inline"
                              />
                              {project.duration.toFixed(0)}s
                            </p>
                          </div>
                        )}
                        <div
                          style={{
                            fontFamily: "SSRegular",
                            marginLeft: 12,
                            marginTop: 10,
                          }}
                        >
                          <p>
                            {project.projectName.slice(0, 30)}
                            {project.projectName.length > 30 ? "..." : ""}
                          </p>
                          <p
                            style={{
                              fontSize: 11,
                              opacity: 0.7,
                              marginTop: -15,
                            }}
                          >
                            {project.createdAt && formatDate(project.createdAt)}
                          </p>
                        </div>
                      </div>
                    </MDBCol>
                  );
                })}
            </MDBRow>
          </MDBCol>
          {loaded && projectsArray.length === 0 && (
            <>
              <MDBCol className="d-flex justify-content-center" size="12">
                <p
                  style={{
                    fontFamily: "SSBold",
                    fontSize: 30,
                    marginTop: 100,
                    textAlign: "center",
                  }}
                >
                  Create your first ad
                </p>
              </MDBCol>

              <MDBCol size="12">
                <p
                  style={{
                    fontFamily: "SSMedium",
                    fontSize: 17,
                    textAlign: "center",
                    marginTop: -10,
                  }}
                >
                  Get started creating your first video ad by clicking the
                  button below
                </p>
              </MDBCol>
              <MDBCol className="d-flex justify-content-center" size="12">
                <div
                  style={{
                    height: 55,
                    width: 270,
                    backgroundColor: "blue",
                    borderRadius: 7,
                    textAlign: "center",
                    fontFamily: "SSRegular",
                    color: "white",
                    fontSize: 18,
                    paddingTop: 14,
                    cursor: "pointer",
                    marginTop: 5,
                  }}
                  onClick={() => createProject()}
                >
                  <p>Create first ad</p>
                </div>
              </MDBCol>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default withRouter(MyProjects);
