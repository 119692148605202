import React, { Component, useState, useEffect } from "react";
import "../index.css";
import "./index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter } from "react-router-dom";
import { firebase } from "../../../firebase/config";
import "react-phone-number-input/style.css";
import { AiOutlineArrowRight, AiOutlineLink } from "react-icons/ai";
import Carousel from "./Carousel";
import ReactPlayer from "react-player";
import { IoColorWandOutline } from "react-icons/io5";
import { RiArrowDropRightFill, RiArrowDropRightLine } from "react-icons/ri";
import { FaArrowRight, FaCoins, FaRegCreditCard } from "react-icons/fa";
import { useSignUpModal } from "../Context/SignUpModal";

const Heading = () => {
  const [youtubeLink, setYoutubeLink] = useState("");
  const [websiteLink, setWebsiteLink] = useState("");
  const { setIsOpen, isOpen } = useSignUpModal();

  function trimString(str) {
    if (str.length <= 20) {
      return str;
    }
    return str.slice(0, 20) + "...";
  }

  return (
    <MDBRow
      style={{
        color: "white",
        paddingTop: 140,
        width: 1380,
        paddingLeft: 29,
        paddingBottom: 30,
      }}
    >
      <MDBCol
        className="d-flex justify-content-center"
        style={{ paddingLeft: 0 }}
        size="12"
      >
        <div
          style={{
            height: 50,
            borderRadius: 100,
            border: "1px solid #bbb",
            textAlign: "center",
            color: "black",
            fontFamily: "SSMedium",
            paddingTop: 12,
            opacity: 0.8,
            paddingRight: 50,
            paddingLeft: 50,
            textDecoration: "underline",
            cursor: "pointer",
          }}
        >
          <p
            style={{
              position: "absolute",
              fontSize: 23,
              marginLeft: -40,
              marginTop: -5,
            }}
          >
            👋
          </p>
          <p> Book a feedback call with our CEO and get a 20% discount</p>
        </div>
      </MDBCol>
      <MDBCol
        className="d-flex justify-content-center"
        style={{ paddingLeft: 0, marginTop: 20 }}
        size="12"
      >
        <p
          style={{
            fontFamily: "SSBold",
            fontSize: 100,
            opacity: 1,
            lineHeight: 0.95,
            color: "black",
            textAlign: "center",
          }}
        >
          Ship ads that{" "}
          <p className="d-inline" style={{ fontStyle: "italic" }}>
            convert
          </p>{" "}
          <br />
          using AI actors
        </p>
      </MDBCol>
      <MDBCol
        className="d-flex justify-content-center"
        style={{ paddingLeft: 0, marginTop: 16 }}
        size="12"
      >
        <p
          style={{
            fontFamily: "SSRegular",
            fontSize: 27,
            marginTop: 0,
            lineHeight: 1.3,
            opacity: 0.95,
            color: "#30312c",
            textAlign: "center",
            fontWeight: "400",
          }}
        >
          Choose from our catalogue of high-performing UGC video ad
          <br />
          templates and create winning ads faster.
        </p>
      </MDBCol>

      <MDBCol
        style={{ paddingTop: 0, marginTop: 16 }}
        className="d-flex justify-content-center"
        size="12"
      >
        <NavLink
          exact
          to="/auth"
          style={{
            height: 65,
            background: "blue",
            borderRadius: 11,
            fontFamily: "SSMedium",
            paddingTop: 14,
            fontSize: 24,
            cursor: "pointer",
            color: "white",
            paddingRight: 55,
            paddingLeft: 55,
          }}
          className="d-flex justify-content-center"
        >
          <p>Try it free</p>
        </NavLink>
      </MDBCol>
      <MDBCol className="d-flex justify-content-center" size="12">
        <div style={{ marginTop: 20, marginLeft: 0 }}>
          <p style={{ color: "black", fontFamily: "SSMedium", opacity: 0.8 }}>
            <FaRegCreditCard style={{ marginRight: 8, marginTop: -3 }} />
            No card required{" "}
            <p className="d-inline" style={{ marginLeft: 5 }}>
              •
            </p>
            <FaCoins
              style={{ marginRight: 8, marginTop: -3, marginLeft: 10 }}
            />
            20 free credits
          </p>
        </div>
      </MDBCol>
    </MDBRow>
  );
};

export default withRouter(Heading);
